
/* Pc는 스크롤 금지 */
@media screen and (min-width : 1200px) {
    /* body {
        overflow: hidden;
    } */
}

.m1, .m5 {
    width: 222px;
    height: 230px;
}


.m2, .m4 {
    width: 122px;
    height: 243px;
    margin-top: -6%;
}

.m3 {
    width: 242px;
    height: 242px;
    margin-top: -16%;
}

.m1 img {
    width: 100%;
    height: 100%;
}
.m2 img {
    width: 100%;
    height: 100%;
}
.m3 img {
    width: 100%;
    height: 100%;
}
.m4 img {
    width: 100%;
    height: 100%;
}
.m5 img {
    width: 100%;
    height: 100%;
}

.m1:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 5px 5px #ffd0a5);
    filter: drop-shadow(-21px 4px 50px #ffd0a5);
    transition: 0.3s;
}
.m2:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 5px 5px #ffd0a5);
    filter: drop-shadow(-21px 4px 50px #ffd0a5);
    transition: 0.3s;
}
.m3:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 5px 5px #ffd0a5);
    filter: drop-shadow(-21px 4px 50px #ffd0a5);
    transition: 0.3s;
}
.m4:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 5px 5px #ffd0a5);
    filter: drop-shadow(-21px 4px 50px #ffd0a5);
    transition: 0.3s;
}

.m5:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 5px 5px #ffd0a5);
    filter: drop-shadow(-21px 4px 50px #ffd0a5);
    transition: 0.3s;
}

.moon-name {
    font-family: 'GabiaHeuldot';
    color: #fff;
    font-size: 40px;
    text-align:center;
    margin-top: 30px;
}

@media screen and (max-width : 800px) {
    /* body {
        margin-bottom: 30px;
        background-color: #433636;
    } */
    .m1, .m5 {
        width: 77px;
        height: 79px;
        margin-left: 65%;
    }

    .m2, .m4 {
        width: 41px;
        height: 81px;
        margin-left: 50%;
    }

    .m3 {
        width: 77px;
        height: 77px;
        margin-left: 20%;
        margin: 20px 0px 20px 20%;
    }

    .moon-name {
        display: none;
    }
}