body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 메인 폰트 */
@font-face {
  font-family: 'GabiaHeuldot';
  src: url(./assets/font/GabiaHeuldot/GabiaHeuldot.ttf);
}

/* 서브 폰트 */


/* 2023 IT SHOW MOON 텍스트 전용 */
@font-face {
  font-family: 'JockeyOne';
  src: url(./assets/font/JockeyOne/JockeyOne-Regular.ttf);
}